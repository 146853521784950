import { ILogger } from './logger';

export default function(logger: ILogger, bundleName: string) {
  // @ts-ignore rollup-plugin-replace + runtime check
  if (typeof VERSION_COMMENT === 'undefined') {
    logger.warn(
      `Failed to print version comment for bundle "${bundleName}". Check configuration of rollup-plugin-replace.`,
    );
    return;
  }

  logger.debug(
    `Loaded payments SDK bundle "${bundleName}" with version comment: `,
    // @ts-ignore rollup-plugin-replace + runtime check above
    VERSION_COMMENT,
  );
}
