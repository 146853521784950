import { SdkConfig, validAddressCollectionOptions, AddressCollectionOption } from '../config';
import { PRODUCTION_PAYMENTS_API } from '../constants';
import { LoggerFactory } from '../logger';

const defaultOmittedConfig = (
  withoutDefaults: SdkConfig,
  loggerFactory: LoggerFactory,
): SdkConfig => {
  const logger = loggerFactory('default-config.ts');

  function defaultStringIfFalsey(field: keyof SdkConfig, defaultVal: string): string {
    if (withoutDefaults[field]) {
      return withoutDefaults[field] as string;
    }
    logger.debug(
      `Config field "${field}" is missing. A default value of "${defaultVal}" will be used.`,
    );
    return defaultVal;
  }

  function defaultJsonString(field: keyof SdkConfig, defaultVal: string): string {
    const defaulted = defaultStringIfFalsey(field, defaultVal);

    try {
      JSON.parse(defaulted);
    } catch (e) {
      logger.warn(
        `Config field "${field}"'s value '${defaulted}' is not valid JSON, defaulting to: '${defaultVal}'`,
      );
      return defaultVal;
    }

    return defaulted;
  }

  function defaultBoolean(field: keyof SdkConfig, defaultVal: boolean): boolean {
    const val = withoutDefaults[field];
    if (typeof val === 'boolean') {
      return val;
    }
    logger.debug(
      `Config field "${field}" is missing. A default value of "${defaultVal}" will be used.`,
    );
    return defaultVal;
  }

  function defaultFeatureCustomAddressCollection(
    field: keyof SdkConfig,
    defaultVal: AddressCollectionOption,
  ): AddressCollectionOption {
    const val = withoutDefaults[field];
    for (let i = 0; i < validAddressCollectionOptions.length; ++i) {
      if (validAddressCollectionOptions[i] === val) {
        return val;
      }
    }
    logger.debug(
      `Config field "${field}" is missing or invalid. A default value of "${defaultVal}" will be used.`,
    );
    return defaultVal;
  }

  return {
    ...withoutDefaults,
    apiUrl: defaultStringIfFalsey('apiUrl', PRODUCTION_PAYMENTS_API),
    skipAddCreditCardCall: Boolean(withoutDefaults.skipAddCreditCardCall),
    dev_enableVerboseLogging: Boolean(withoutDefaults.dev_enableVerboseLogging),
    uiAcceptJsButtonText: defaultStringIfFalsey('uiAcceptJsButtonText', 'Save Card'),
    uiAcceptJsHeaderText: defaultStringIfFalsey('uiAcceptJsHeaderText', 'Card Information'),
    uiAcceptJsBillingAddressOptionsJson: defaultJsonString(
      'uiAcceptJsBillingAddressOptionsJson',
      '{"show":false, "required":false}',
    ),
    uiSdkAddressButtonText: defaultStringIfFalsey('uiSdkAddressButtonText', 'Continue'),
    uiSdkAddressHeaderText: defaultStringIfFalsey('uiSdkAddressHeaderText', 'Card Billing Address'),
    featurePrefillAddress: defaultBoolean('featurePrefillAddress', true),
    featureCustomAddressCollection: defaultFeatureCustomAddressCollection(
      'featureCustomAddressCollection',
      false,
    ),
    uiSdkBackgroundColor: defaultStringIfFalsey('uiSdkBackgroundColor', 'rgba(0, 0, 0, 0.1)'),
    uiSdkCloseButtonAriaAndTitle: defaultStringIfFalsey('uiSdkCloseButtonAriaAndTitle', 'Close'),
    uiSdkCloseButtonStyles: defaultStringIfFalsey(
      'uiSdkCloseButtonStyles',
      'display: block; position: fixed; top: 0; right: 0; color: white; z-index: 1000000; padding: 10px; font-size: 30px; background: transparent; border: none; cursor: pointer;',
    ),
    uiSdkHideLoader: Boolean(withoutDefaults.uiSdkHideLoader),
    uiSdkLoaderText: defaultStringIfFalsey('uiSdkLoaderText', 'Loading...'),
    uiSdkLoaderColor: defaultStringIfFalsey('uiSdkLoaderColor', '#434958'),
    vendorMetadata: withoutDefaults.vendorMetadata || [],
  };
};

export default defaultOmittedConfig;
