import { SdkConfig, validate as validateConfig } from '../config';
import { getLoggerFactory } from '../logger';
import SDK from './sdk';
import findFolder from './find-folder';
import defaultOmittedConfig from './default-config';
import cleanConfig from './clean-config';
import IFrameClass from './iframe';
import logVersionComment from '../log-version-comment';

logVersionComment(console, 'sdk/index.ts');

function createSDK(rawConfig: SdkConfig): SDK | null {
  const loggerFactory = getLoggerFactory(Boolean(rawConfig.dev_enableVerboseLogging));
  const logger = loggerFactory('sdk/index.ts');

  logger.debug('called createSDK');

  const injections = {
    folder: findFolder(document, logger),
    loggerFactory,
  };

  validateConfig(rawConfig);
  const configWithDefaults = defaultOmittedConfig(rawConfig, loggerFactory);
  const cleanedConfig = cleanConfig(configWithDefaults, loggerFactory, window);

  return new SDK({ injections, config: cleanedConfig, IFrameClass });
}

export default {
  createSDK,
};
