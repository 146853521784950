import { SdkConfig } from './config';

type REQUEST_TYPE = 'gw-wps/config/request';

type RESPONSE_TYPE = 'gw-wps/config/response';

const requestType: REQUEST_TYPE = 'gw-wps/config/request';

const responseType: RESPONSE_TYPE = 'gw-wps/config/response';

export interface IConfigRequest {
  type: REQUEST_TYPE;
}

export interface IConfigResponse {
  type: RESPONSE_TYPE;
  payload: IFrameConfig;
}

export interface IFrameConfig {
  folder: string;
  config: SdkConfig;
  isAlreadyCollecting: boolean;
  isVerboseLoggingEnabled: boolean;
}

export const makeConfigRequest = (): IConfigRequest => ({
  type: requestType,
});

export const makeConfigResponse = ({ config }: { config: IFrameConfig }): IConfigResponse => ({
  type: responseType,
  payload: config,
});

export const isConfigRequest = (event: MessageEvent) => {
  return event.data && event.data.type === requestType;
};

export const isConfigResponse = (event: MessageEvent) => {
  return event.data && event.data.type === responseType;
};
