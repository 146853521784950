export default {
  INVALID_ARG_COLLECT_AND_ASSOCIATE_CARD:
    'caller-error-invalid-argument-collect-and-associate-card',
  API_ERROR_ADD_CC_STATUS: 'api-error-add-credit-card-response-status',
  API_ERROR_CC_NOT_JSON: 'api-error-credit-card-response-is-not-json',
  API_ERROR_VENDOR_CONFIG_STATUS: 'api-error-vendor-config-response-status',
  API_ERROR_VENDOR_CONFIG_NOT_JSON: 'api-error-vendor-config-response-is-not-json',
  ERROR_CREATING_VENDOR: 'error-creating-vendor',
  FAILED_TO_FETCH: 'failed-to-fetch',
};
