import { SdkConfig } from '../config';
import { ResultMessageToCaller } from '../message-types';
import { isConfigRequest, makeConfigResponse } from '../iframe-config-messages';
import { ILogger, LoggerFactory } from '../logger';

import {
  isCollectionResponse,
  makeCollectionRequest,
  ICollectionResponse,
} from '../iframe-collection-messages';

let instanceCounter = 0;

export type Callback = (response: ResultMessageToCaller) => void;

export interface IIFrameConstructor {
  new ({
    folder,
    sdkConfig,
    loggerFactory,
  }: {
    folder: string;
    sdkConfig: SdkConfig;
    loggerFactory: LoggerFactory;
  }): IIFrame;
}

export interface IIFrame {
  readonly element: HTMLIFrameElement;
  show(): void;
  hide(): void;
  remove(): void;
  collectAndAssociateCard(callback: Callback): void;
}

const IFrame: IIFrameConstructor = class IFrame implements IIFrame {
  public readonly element: HTMLIFrameElement;
  private readonly id: string;
  private readonly folder: string;
  private readonly sdkConfig: SdkConfig;
  private readonly logger: ILogger;
  private isCollecting: boolean;

  constructor({
    folder,
    sdkConfig,
    loggerFactory,
  }: {
    folder: string;
    sdkConfig: SdkConfig;
    loggerFactory: LoggerFactory;
  }) {
    this.id = instanceCounter.toString();
    ++instanceCounter;
    this.element = document.createElement('iframe');
    this.element.id = `gw-wps__collection-iframe-${this.id}`;
    this.folder = folder;
    this.sdkConfig = sdkConfig;
    this.isCollecting = false;
    this.logger = loggerFactory(`sdk/iframe.ts, id:${this.id}`);

    this.logger.debug('created iFrame');

    this.setInitialStyles();
    this.listenForConfigRequests();
    this.loadIFrame();
  }

  public show() {
    this.element.style.display = 'block';
  }

  public hide() {
    this.element.style.display = 'none';
  }

  public remove() {
    this.logger.debug('removing iFrame');
    this.hide();
    const { element } = this;
    if (element.parentNode) {
      element.parentNode.removeChild(element);
    }
  }

  public collectAndAssociateCard(callback: Callback): void {
    const iFrameWindow = this.element.contentWindow;
    if (!iFrameWindow) {
      const msg = 'internal error - iFrame has no window';
      this.logger.error(msg);
      throw new Error(msg);
    }

    this.show();
    this.isCollecting = true;

    const handler = (event: MessageEvent) => {
      if (!isCollectionResponse(event)) {
        this.logger.debug('collection listener ignoring', event);
        return;
      }
      if (!this.isCollecting) {
        return;
      }

      this.logger.debug('handling collection response', event);
      const collectionResponse: ICollectionResponse = event.data;
      callback(collectionResponse.payload);
      window.removeEventListener('message', handler);
      this.isCollecting = false;
      this.hide();
    };
    window.addEventListener('message', handler, false);

    const message = makeCollectionRequest();
    this.logger.debug('sending collection request to iFrame', message);
    iFrameWindow.postMessage(message, '*');
  }

  private setInitialStyles() {
    const iFrame = this.element;
    iFrame.frameBorder = '0';
    iFrame.style.width = '100%';
    iFrame.style.height = '100%';
    iFrame.style.position = 'fixed';
    iFrame.style.top = '0';
    iFrame.style.left = '0';
    iFrame.style.zIndex = '9999999';
    iFrame.style.background = this.sdkConfig.uiSdkBackgroundColor;
    iFrame.style.display = 'none';
  }

  private loadIFrame() {
    this.element.src = `${this.folder}collection-iframe.html#${this.id}`;
    document.body.appendChild(this.element);
  }

  private listenForConfigRequests() {
    const handler = (event: MessageEvent) => {
      if (!isConfigRequest(event)) {
        this.logger.debug('config listener ignoring', event);
        return;
      }

      this.logger.debug('handling config request', event);

      const iFrameWindow = this.element.contentWindow;
      if (!iFrameWindow) {
        this.logger.error('iFrame does not have contentWindow. Cannot send config response.');
        return;
      }

      const message = makeConfigResponse({
        config: {
          folder: this.folder,
          config: this.sdkConfig,
          isAlreadyCollecting: this.isCollecting,
          isVerboseLoggingEnabled: this.sdkConfig.dev_enableVerboseLogging,
        },
      });

      this.logger.debug('sending config response to iFrame', message);
      iFrameWindow.postMessage(message, '*');
      window.removeEventListener('message', handler);
    };

    window.addEventListener('message', handler, false);
  }
};

export default IFrame;
