import { ILogger } from '../logger';
import { PRODUCTION_SDK_FOLDER } from '../constants';

const ensureTrailingSlash = (path: string, logger: ILogger): string => {
  // sdk is not polyfilled
  const lastChar = path.substring(path.length - 1, path.length);
  if (lastChar !== '/') {
    const updated = `${path}/`;
    logger.warn(`cleaned folder path from "${path}" to "${updated}"`);
    return updated;
  }
  logger.debug(`folder path: "${path}"`);
  return path;
};

/**
 * Determine the folder hosting this SDK by checking for a data-attribute and falling back
 * to a hardcoded (production) url if it missing. Alternate approaches exist but none of them
 * are bulletproof in IE10.
 *
 * Returned folder will have a forward slash (/).
 *
 * See:
 * - http://2ality.com/2014/05/current-script.html
 * - https://stackoverflow.com/questions/984510/what-is-my-script-src-url/984656#comment8909091_984656
 *
 * We'll need additional files from here (like the iFrame used for isolating vendor code) and
 * cannot use `window.location` because that is the consumer of the SDK not the SDK itself.
 */
export default function findFolder(document: Document, logger: ILogger): string {
  const script: HTMLScriptElement | null = document.querySelector('script[data-gw-payments-sdk]');
  console.log(script);
  const folder = ((script && script.src) || '').split('gw-payments-sdk.js')[0];
  console.log(folder);
  if (!folder) {
    logger.warn(
      `Did not find folder for SDK, falling back to hardcoded production location "${PRODUCTION_SDK_FOLDER}".`,
    );
    return ensureTrailingSlash(PRODUCTION_SDK_FOLDER, logger);
  }

  logger.debug(`Found SDK folder location: "${folder}"`);
  if (folder !== PRODUCTION_SDK_FOLDER) {
    logger.warn(
      `Using a non-production SDK folder: "${folder}". Production is: "${PRODUCTION_SDK_FOLDER}".`,
    );
  }

  return ensureTrailingSlash(folder, logger);
}
