import { validProviderIds } from './collection-iframe/payment-providers/common-types';

interface IRequiredSdkConfig {
  paymentConfigId: number;
  tenant: string;
}

export const validAddressCollectionOptions = [...validProviderIds, '*', true, false] as const;

export type AddressCollectionOption = typeof validAddressCollectionOptions[number];

export interface IOptionalCardDetails {
  cardDescription?: string;
}

export interface IOptionalFeatureConfig {
  featurePrefillAddress: boolean;
  featureCustomAddressCollection: AddressCollectionOption;
}

export interface IOptionalSdkUiConfig {
  uiAcceptJsButtonText: string;
  uiAcceptJsHeaderText: string;
  uiAcceptJsBillingAddressOptionsJson: string;
  uiSdkAddressButtonText: string;
  uiSdkAddressHeaderText: string;
  uiSdkBackgroundColor: string; // css color like 'rgba(0, 0, 0, 0.1)'
  uiSdkCloseButtonAriaAndTitle: string; // string like 'Close'
  uiSdkCloseButtonStyles: string; // string of inline styles for close button
  uiSdkHideLoader: boolean;
  uiSdkLoaderText: string; // string like 'Loading...'
  uiSdkLoaderColor: string; // css color
}

/*
 * This format matches the vendor metadata format used by the api.
 * Vendor metadata is currently used for One Inc's "ClientReferenceData" fields (which support searching in their portal).
 * Note: Authorize.net has no matching concept in the client side SDK (Accept.js)
 *
 * See:
 * | Vendor        | Docs |
 * | One Inc       | https://developers.oneincsystems.com/Help/Documentation/PortalOne#doc-request-objects |
 * | Authorize.net | https://developer.authorize.net/api/reference/features/acceptjs.html#Integrating_the_Hosted_Payment_Information_Form |
 */
export interface IVendorMetadata {
  vendorMetadata: IVendorMetadataItem[] | undefined;
}

export interface IVendorMetadataItem {
  key: string;
  value: string;
}

export interface ICustomerAddress {
  customerAddress: string; // see: INS-4331, INS-4674
  customerCity: string;
  customerCountry: string; // code like 'US'
  customerFirstName: string;
  customerLastName: string;
  customerState: string; // abbrev like 'AL'
  customerZip: string; // 5 digit works, One Inc does not support zip+4
}

interface IOptionalSdkConfig extends IOptionalSdkUiConfig, IVendorMetadata, ICustomerAddress {
  apiUrl: string; // SDK will default if not provided
  customerEmail: string;
  dev_enableVerboseLogging: boolean;
  skipAddCreditCardCall: boolean;
  paymentCategory: string; // will default to CreditCard for backwards Compatibility, “CreditCard“, “ECheck”, or “UserSelect“
}

interface ISdkConfig
  extends IRequiredSdkConfig,
    IOptionalSdkConfig,
    IOptionalFeatureConfig,
    IOptionalCardDetails {}

export type SdkConfig = Readonly<ISdkConfig>;

const requiredFields: (keyof IRequiredSdkConfig)[] = ['paymentConfigId', 'tenant'];

export function validate(config: unknown) {
  if (!config) {
    throw new Error('missing config');
  }
  if (!(config instanceof Object)) {
    throw new Error('config must be an object');
  }

  requiredFields.forEach((f) => {
    if (!config.hasOwnProperty(f)) {
      throw new Error(`Required field "${f}" is missing. Required fields are: ${requiredFields}.`);
    }
  });
}
