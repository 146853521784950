import { SdkConfig } from '../config';
import { LoggerFactory } from '../logger';

const cleanConfig = (
  unClean: SdkConfig,
  loggerFactory: LoggerFactory,
  window: Window,
): SdkConfig => {
  const logger = loggerFactory('clean-config.ts');

  let apiUrl = unClean.apiUrl;
  // can't use ".endsWith", SDK bundle is not polyfilled
  if (apiUrl.substr(-1) === '/') {
    logger.debug(`Provided apiUrl ends with a slash "${apiUrl}"`);
    apiUrl = apiUrl.substring(0, apiUrl.length - 1);
    logger.debug(`apiUrl cleaned to "${apiUrl}"`);
  }

  let dev_enableVerboseLogging = unClean.dev_enableVerboseLogging;
  if (window.gwpsForceVerboseLogging) {
    logger.warn('Forcing verbose logging because `window.gwpsForceVerboseLogging` is true.');
    dev_enableVerboseLogging = true;
  }

  return {
    ...unClean,
    apiUrl,
    dev_enableVerboseLogging,
  };
};

export default cleanConfig;
