import { noop } from './utils';

type TimeStamp = () => string;

export const defaultTimeStamp: TimeStamp = () => {
  const d = new Date();
  return `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}.${d.getMilliseconds()}`;
};

type logMethod = (...msgs: unknown[]) => void;

export interface ILogger {
  debug: logMethod;
  error: logMethod;
  info: logMethod;
  log: logMethod;
  warn: logMethod;
}

export type LoggerFactory = (filename: string) => ILogger;

export const getLoggerFactory = (
  isVerbose: boolean,
  baseLogger: ILogger = console,
  ts: TimeStamp = defaultTimeStamp,
): LoggerFactory => (filename: string): ILogger => ({
  debug: isVerbose
    ? (...msgs) => {
        baseLogger.debug(`${ts()} [${filename}] `, ...msgs);
      }
    : noop,
  error(...msgs) {
    baseLogger.error(`${ts()} [${filename}] `, ...msgs);
  },
  info: isVerbose
    ? (...msgs) => {
        baseLogger.info(`${ts()} [${filename}] `, ...msgs);
      }
    : noop,
  log: isVerbose
    ? (...msgs) => {
        baseLogger.log(`${ts()} [${filename}] `, ...msgs);
      }
    : noop,
  warn(...msgs) {
    baseLogger.warn(`${ts()} [${filename}] `, ...msgs);
  },
});

export default getLoggerFactory;
