import { ResultMessageToCaller } from './message-types';

type REQUEST_TYPE = 'gw-wps/collection/request';

type RESPONSE_TYPE = 'gw-wps/collection/response';

const requestType: REQUEST_TYPE = 'gw-wps/collection/request';

const responseType: RESPONSE_TYPE = 'gw-wps/collection/response';

export interface ICollectionRequest {
  type: REQUEST_TYPE;
}

export interface ICollectionResponse {
  type: RESPONSE_TYPE;
  payload: ResultMessageToCaller;
}

export const makeCollectionRequest = (): ICollectionRequest => ({
  type: requestType,
});

export const makeCollectionResponse = ({
  messageToCaller,
}: {
  messageToCaller: ResultMessageToCaller;
}): ICollectionResponse => ({
  type: responseType,
  payload: messageToCaller,
});

export const isCollectionRequest = (event: MessageEvent) => {
  return event.data && event.data.type === requestType;
};

export const isCollectionResponse = (event: MessageEvent) => {
  return event.data && event.data.type === responseType;
};
