import { SdkConfig } from '../config';
import { IInjections } from './injections';
import { ILogger } from '../logger';
import Errors from '../errors';
import { ResultMessageToCaller } from '../message-types';
import { IIFrameConstructor, IIFrame } from './iframe';
import { getErrorMessage } from '../utils';

export default class SDK {
  private readonly config: SdkConfig;
  private readonly injections: IInjections;
  private readonly IFrameClass: IIFrameConstructor;
  private readonly logger: ILogger;

  private iFrame: IIFrame;
  private isActive: boolean;

  constructor({
    config,
    injections,
    IFrameClass,
  }: {
    config: SdkConfig;
    injections: IInjections;
    IFrameClass: IIFrameConstructor;
  }) {
    this.config = config;
    this.injections = injections;
    this.IFrameClass = IFrameClass;
    this.logger = injections.loggerFactory('sdk/sdk.ts');
    this.isActive = true;

    // we're not using @babel/plugin-proposal-class-properties
    this.collectAndAssociateCard = this.collectAndAssociateCard.bind(this);

    this.iFrame = new this.IFrameClass({
      folder: this.injections.folder,
      sdkConfig: this.config,
      loggerFactory: this.injections.loggerFactory,
    });
  }

  // it would be simpler to return a promise for the result than deal with the callback
  //  but IE11 does not support Promises and the main bundle does not polyfill anything
  //  (only the iFrame bundle polyfills)
  public collectAndAssociateCard(callback: unknown) {
    this.logger.debug('called collectAndAssociateCard');

    // runtime check needed because this is exposed to the consumer of the SDK
    if (typeof callback !== 'function') {
      this.logger.error(
        '`collectAndAssociateCard` must be called with a function. It was called with: ',
        callback,
      );
      throw new TypeError(Errors.INVALID_ARG_COLLECT_AND_ASSOCIATE_CARD);
    }

    const handleUnexpectedError = (e: Error) => {
      this.invokeCallback(callback, {
        type: 'ERROR',
        debug: {
          errorMessage: getErrorMessage(e),
        },
      });
      this.logger.error(e);
    };

    try {
      if (!this.isActive) {
        throw new Error('cannot call `collectAndAssociateCard` after `deactivate`');
      }

      this.iFrame.collectAndAssociateCard((response) => {
        this.invokeCallback(callback, response);
      });
    } catch (e: any) {
      handleUnexpectedError(e);
    }
  }

  public deactivate() {
    this.logger.debug('called deactivate');
    this.iFrame.remove();
    this.isActive = false;
  }

  private invokeCallback(callback: Function, message: ResultMessageToCaller) {
    if (message.type === 'ERROR') {
      this.logger.error('error message sent to parent: ', message);
    }

    this.logger.debug('invoking callback with message', message);

    try {
      callback(message);
    } catch (e: any) {
      this.logger.error(
        'Error invoking the provided callback. Message passed to callback and resulting error: ',
        message,
        e,
      );
    }

    this.iFrame.remove();
    this.iFrame = new this.IFrameClass({
      folder: this.injections.folder,
      sdkConfig: this.config,
      loggerFactory: this.injections.loggerFactory,
    });
  }
}
